const custodyKyc = {
    'custodyKyc.typeofCorporation': '公司类型',
    'custodyKyc.annualBusinessRevenue': '预计年营业收入',
    'custodyKyc.OrganizationName': '组织/机构名称',
    'custodyKyc.DescriptionBusiness': '公司经营范围',
    'custodyKyc.OfficialWebsiteAddress': '公司官网地址',
    'custodyKyc.Top5countries': '公司客户所在前五的国家',
    'custodyKyc.RegisteredAddress': '公司注册地址',
    'custodyKyc.BusinessAddress': '营业地址',
    'custodyKyc.ContactInformation': '联系信息',
    'custodyKyc.FirstName': '名',
    'custodyKyc.MeddieName': '中间名',
    'custodyKyc.LaetName': '姓',
    'custodyKyc.DateBirrth': '出生日期',
    'custodyKyc.Nationality': '国籍',
    'custodyKyc.Role': '角色',
    'custodyKyc.OwnershipPercent': '持股比例',
    'custodyKyc.EntryType': '组织类型',
    'custodyKyc.Country': '国家或地区',
    'custodyKyc.City': '城市',
    'custodyKyc.State': '省/州',
    'custodyKyc.Address': '地址',
    'custodyKyc.idCard': '上传身份证明文件身份证正反面或护照',
    'custodyKyc.registeredCountry': '注册地区',
    'custodyKyc.CorporateName': '公司名称',
    'custodyKyc.businessCountry': '公司展业地址',

    'custodyKyc.form.label.OpportunityName': '机构（商机）',
    'custodyKyc.form.label.newActivationLink': '通过账户中心注册',

    'custodyKyc.button.newApplication': '创建申请链接',
    'custodyKyc.button.AdditionalInformation': '补充资料',
    'custodyKyc.button.EnterpriseAttachmentManage': '企业附件管理',
    'custodyKyc.button.DataModification': '资料修改',
    'custodyKyc.button.Rejected': '审核拒绝',
    'custodyKyc.button.Approval': '审核通过',
    'custodyKyc.button.resendEmail': '重发邮件',
    'custodyKyc.button.openAccount': '确认开户',
    'custodyKyc.button.OpeningMethod': '开户方式',
    'custodyKyc.button.NewOrg': '新增Org',
    'custodyKyc.button.ExistingOrg': '已有Org',

    'custodyKyc.gridTitle.AllApplicatio': '申请池',
    'custodyKyc.gridTitle.PreApproval': '预审核',
    'custodyKyc.gridTitle.ComplianceReview': '合规审核',
    'custodyKyc.gridTitle.ContractUpload': '合同上传',
    'custodyKyc.gridTitle.Approved': '审核通过',
    'custodyKyc.gridTitle.Rejected': '开户拒绝',
    'custodyKyc.gridTitle.Datasync': '资料同步',
    'custodyKyc.gridTitle.InvitationFailed': '邀请失败',
    'custodyKyc.gridTitle.EmailSent': '已发邀请邮件',

    'custodyKyc.gridTitle.AccountOpened': '已开户',
    'custodyKyc.select.NotSelected': '未选择',
    'custodyKyc.select.ApplicationID': '申请ID',
    'custodyKyc.select.ContactNumber': '联系人手机号',
    'custodyKyc.select.ContactEmail': '联系人邮箱',

    'custodyKyc.table.title.ApplicationTime': '申请时间',
    'custodyKyc.table.title.ApplyBusiness': '申请业务',
    'custodyKyc.table.title.ContactEmail': '联系客户邮箱',
    'custodyKyc.table.title.CustomerStatus': '客户状态',
    'custodyKyc.table.title.CustomerName': '客户名',
    'custodyKyc.table.title.ApplicationID': 'Custody客户申请ID',

    'custodyKyc.company.descriptions.CompanyInfor': '公司/组织信息',
    'custodyKyc.company.descriptions.BusinessJurisdiction': '业务管辖区',
    'custodyKyc.company.descriptions.ContactNumber': '联系人手机号',
    'custodyKyc.company.descriptions.CompanyRegistrationNumber': '公司注册号',
    'custodyKyc.company.descriptions.Address': '地址',
    'custodyKyc.company.descriptions.ContactEmail': '联系人邮箱',
    'custodyKyc.company.descriptions.CompanyEstablishmentTime': '公司成立时间',
    'custodyKyc.company.CarriedOutInCountries': '公司业务是否在以下任意一个国家或地区开展',
    'custodyKyc.company.Majorcustomers': '公司主要客户（至少填写 1 位）',
    'custodyKyc.company.descriptions.uploadFile': '上传文件',

    // 合作信息
    'custodyKyc.company.descriptions.CooperationInfor': '合作信息',
    'custodyKyc.company.descriptions.cooperationType': '合作类型',
    'custodyKyc.company.descriptions.assetsType': '托管资产类型',
    'custodyKyc.company.descriptions.purspose': '开展业务关系的目的',
    'custodyKyc.company.descriptions.CompanyType': '公司类型',

    'custodyKyc.company.descriptions.EstimatedAssetsSize': '公司预计托管资产规模',
    'custodyKyc.company.descriptions.assetsMainSource': '公司托管资产的主要来源',
    'custodyKyc.company.descriptions.TransactionsMonthlyNumber': '月交易次数',
    'custodyKyc.company.descriptions.transactionMonthlyAmount': '月交易金额',
    'custodyKyc.company.descriptions.EstimatedAnnualIncome': '预计年营业收入',
    'custodyKyc.crp.descriptions.CRPInformation': '企业责任人信息',
    'custodyKyc.crp.descriptions.Individuals': '个人',
    'custodyKyc.crp.descriptions.Institution': '机构',
    'custodyKyc.crp.descriptions.EnterpriseAnnexInformation': '企业附件信息',
    'custodyKyc.crp.descriptions.FirstAdministratorsInformation': '首位管理人员信息',
    'custodyKyc.crp.descriptions.PhoneNumber': '手机号',
    'custodyKyc.crp.descriptions.E-mail': '邮箱',
    'custodyKyc.crp.descriptions.Title': '职位',
    'custodyKyc.crp.descriptions.SocialContact': '社交联系方式',
    'custodyKyc.crp.descriptions.IdentityDocument': '身份证件',

    'custodyKyc.log.Title': '操作日志',
    //
    'custodyKyc.UserInfo.title': '个人责任人信息',
    'custodyKyc.UserInfo.descriptions.UserInformation': '用户基本信息',
    'custodyKyc.UserInfo.descriptions.CDDInformation': 'CDD信息',
    'custodyKyc.UserInfo.descriptions.AdditionalInformation': '补充资料',
    'custodyKyc.UserInfo.descriptions.Back': '返回',
    'custodyKyc.UserInfo.button.AddIndividualCRP': '新增个人CRP',
    'custodyKyc.UserInfo.descriptions.licenseNumber': '证件号',
    'custodyKyc.UserInfo.descriptions.verifiedId': '身份证件是否已验证',
    'custodyKyc.UserInfo.descriptions.certificateType': '证件类型',
    'custodyKyc.UserInfo.descriptions.passport': '护照',
    'custodyKyc.UserInfo.descriptions.idCard': '身份证',
    'custodyKyc.UserInfo.descriptions.drivingLicense': '驾照',
    'custodyKyc.UserInfo.descriptions.residencePermit': '居住证',
    'custodyKyc.UserInfo.descriptions.upload': '上传',
    'custodyKyc.UserInfo.descriptions.front': '正面',
    'custodyKyc.UserInfo.descriptions.back': '反面',

    'custodyKyc.UserInfo.descriptions.files': '证件',

    'custodyKyc.UserInfo.button.AddInstitutionCRP': '新增机构CRP',
    'custodyKyc.UserInfo.label.Switch': '切换责任人',

    'custodyKyc.UserInfo.descriptions.ActualShareholdingPercentage': '实际持股比例',
    'custodyKyc.UserInfo.descriptions.UploadID': '上传身份证',

    'custodyKyc.UserInfo.descriptions.BirthDate': '出生日期',
    'custodyKyc.UserInfo.descriptions.Gender': '性别',
    'custodyKyc.UserInfo.descriptions.residence': '居住地',
    'custodyKyc.UserInfo.descriptions.AddPRInformation': '新增个人责任人信息',
    'custodyKyc.UserInfo.descriptions.businessCountry': '展业国家',
    'custodyKyc.UserInfo.descriptions.registeredCountry': '注册国家',
    'custodyKyc.UserInfo.descriptions.CompanyName': '公司名称',
    'custodyKyc.UserInfo.descriptions.AddCompanyCRP': '新增企业责任人信息',
    'custodyKyc.UserInfo.descriptions.CountryIssue': '签发国',
    'custodyKyc.UserInfo.descriptions.UpdateTime': '更新时间',
    'custodyKyc.UserInfo.descriptions.DateCreation': '创建时间',
    'custodyKyc.UserInfo.descriptions.AuthorisedSignatory': '授权签字证明',
    'custodyKyc.UserInfo.descriptions.IdentityDocument': '身份证明',
    'custodyKyc.UserInfo.descriptions.AuthorisedSignatoryInfo': '授权人签字信息',
    'custodyKyc.UserInfo.descriptions.AdministratorIDDocument': '首位管理人身份证件',

    // 规管信息

    'custodyKyc.compliance.title': '规管信息',
    'custodyKyc.compliance.promiseLegal':
        ' 我/本司承诺使用Cobo的服务的目的是合法的，所处理的有关资金不是任何犯罪的收益。我/本司' +
        '确认并承诺上述信息及材料全部真实、合法、有效，且明白Cobo将依赖并使用所做的声明和提供的' +
        '信息的准确性和完整性。我/本司同意在服务期间，Cobo 不定期的要求资料更新，并愿意配合提供相关资料。',
    'custodyKyc.compliance.hasAdoptedPolicies': '公司是否制定反洗钱相关制度或措施',
    'custodyKyc.compliance.hasArbitrationOrPenalties':
        '截至本表单填写之⽇，公司是否存在已经发⽣、正在进行和/或可预见的潜在诉讼、仲裁或行政处罚情况？ 如存在，请说明相关情况',
    'custodyKyc.compliance.threeYearsHasArbitrationOrPenalties':
        '截至本表单填写之⽇，近三年，公司的法定代表人、授权签字人、股东、实际控制人是否存在已经发⽣、正在进行和可预预见的潜在诉讼、仲裁或行政处罚情况？如存在，请说明相关情况',
    'custodyKyc.compliance.hasRelevantApplicableLaws':
        '公司是否受过任何监管部⻔处罚或被采取过任何监管措施？ 如存在，请说明相关情况?',
    'custodyKyc.compliance.hasDepositsFraudOrICO':
        '公司、公司股东、法定代表人、授权签字人、实际控制人是否曾因洗钱、⾮法吸收公众存款、集资诈骗、ICO 等行为被任何国家的任何监管机构⽴案调查或涉及任何仲裁、诉讼等司法程序？ 如有，请说明相关情况',
    'custodyKyc.compliance.materialsIsValid': '您/贵司是否确认并承诺上述信息及材料全部真实、合法、有效',
    'custodyKyc.compliance.agreeingBelow':
        '本客户承诺使用Cobo的服务的目的是合法的，所处理的有关资金不是任何犯罪的收益。本客户证明所提供的信息和数据是真实、完整和正确的，且明白Cobo将依赖并使用所做的声明和提供的信息的准确性和完整性。如有资料变更并将及时通知Cobo任何变化',
};
export default custodyKyc;
