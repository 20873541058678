const custodyKyc = {
    'custodyKyc.typeofCorporation': 'Type of Corporation',
    'custodyKyc.annualBusinessRevenue': 'Estimated annual business revenue',
    'custodyKyc.OrganizationName': 'Organization name',
    'custodyKyc.DescriptionBusiness': 'Description of Business',
    'custodyKyc.OfficialWebsiteAddress': 'Official Website Address',
    'custodyKyc.Top5countries': 'Top 5 countries your clients are based in',
    'custodyKyc.RegisteredAddress': 'Registered Address',
    'custodyKyc.BusinessAddress': 'Business address',
    'custodyKyc.ContactInformation': 'Contact Information',
    'custodyKyc.FirstName': 'First name',
    'custodyKyc.MeddieName': 'Meddle name',
    'custodyKyc.LaetName': 'Last name',
    'custodyKyc.DateBirrth': 'Date of birrth',
    'custodyKyc.Nationality': 'Nationality',
    'custodyKyc.Role': 'Role',
    'custodyKyc.OwnershipPercent': 'Ownership percentage',
    'custodyKyc.EntryType': 'Entry Type',
    'custodyKyc.Country': 'Country',
    'custodyKyc.City': 'City',
    'custodyKyc.State': 'State/Province',

    'custodyKyc.Address': 'Address',
    'custodyKyc.idCard': 'identificant card',
    'custodyKyc.CorporateName': 'Corporate name',
    'custodyKyc.registeredCountry': 'Country of registered',
    'custodyKyc.businessCountry': 'Country of business',
    'custodyKyc.form.label.OpportunityName': 'Opportunity name',
    'custodyKyc.form.label.newActivationLink': 'register through account center',

    'custodyKyc.button.newApplication': 'New application',
    'custodyKyc.button.AdditionalInformation': 'Additional Information',
    'custodyKyc.button.EnterpriseAttachmentManage': 'Enterprise Attachments management',
    'custodyKyc.button.DataModification': 'Data Modification',
    'custodyKyc.button.Rejected': 'Rejected',
    'custodyKyc.button.Approval': 'Approval',
    'custodyKyc.button.resendEmail': 'Resend Email',
    'custodyKyc.button.openAccount': 'Open Account ',
    'custodyKyc.button.ExistingOrg': 'Existing Organization',
    'custodyKyc.button.OpeningMethod': 'Account Opening Method',
    'custodyKyc.button.NewOrg': 'New Organization',

    'custodyKyc.gridTitle.AllApplicatio': 'All application',
    'custodyKyc.gridTitle.PreApproval': 'Pre-Approval',
    'custodyKyc.gridTitle.ComplianceReview': 'Compliance Review',
    'custodyKyc.gridTitle.Approved': 'Approved',
    'custodyKyc.gridTitle.ContractUpload': 'Contract Upload',
    'custodyKyc.gridTitle.Rejected': 'Rejected',

    'custodyKyc.gridTitle.EmailSent': 'Invitation Email Sent',
    'custodyKyc.gridTitle.InvitationFailed': 'Invitation Failed',
    'custodyKyc.gridTitle.Datasync': 'Data sync',
    'custodyKyc.gridTitle.AccountOpened': 'Account Opened',
    'custodyKyc.select.NotSelected': 'Not Selected',
    'custodyKyc.select.ApplicationID': 'Application ID',
    'custodyKyc.select.ContactNumber': 'Contact Number',
    'custodyKyc.select.ContactEmail': 'Contact E-mail',
    'custodyKyc.table.title.ApplicationTime': 'Application time',
    'custodyKyc.table.title.ContactEmail': 'Customer Contact E-mail',
    'custodyKyc.table.title.ApplyBusiness': 'Business Application',
    'custodyKyc.table.title.CustomerStatus': 'Customer Status',
    'custodyKyc.table.title.CustomerName': 'Customer Name',
    'custodyKyc.table.title.ApplicationID': 'Application ID',
    'custodyKyc.company.descriptions.CompanyInfor': 'Company/Organization Information',
    'custodyKyc.company.descriptions.BusinessJurisdiction': 'Business Jurisdiction',
    'custodyKyc.company.CarriedOutInCountries':
        "Whether Company's Business Is Carried Out In Any of The Following Countries or Regions。",
    'custodyKyc.company.Majorcustomers': 'Major customers ',
    'custodyKyc.company.descriptions.uploadFile': 'Upload Files',
    'custodyKyc.company.descriptions.CompanyRegistrationNumber': 'Company Registration Number',
    'custodyKyc.company.descriptions.Address': 'Address',
    'custodyKyc.company.descriptions.ContactEmail': 'Contact E-mail',
    'custodyKyc.company.descriptions.CompanyEstablishmentTime': 'Company Establishment Time',
    'custodyKyc.company.descriptions.CooperationInfor': 'Corperation Information',
    'custodyKyc.company.descriptions.cooperationType': 'Type of Corperation',
    'custodyKyc.company.descriptions.assetsType': 'Type of Assets to be Custodised',
    'custodyKyc.company.descriptions.purspose': 'Purpose of business relationship',
    'custodyKyc.company.descriptions.CompanyType': 'Company Type',
    'custodyKyc.company.descriptions.ContactNumber': 'Contact Number',
    'custodyKyc.company.descriptions.EstimatedAssetsSize': 'Estimated Size of Assets to be Custodised',
    'custodyKyc.company.descriptions.assetsMainSource': 'Source of Assets to be Custodised',
    'custodyKyc.company.descriptions.TransactionsMonthlyNumber': 'Estimated Number of Monthly Transactions',
    'custodyKyc.company.descriptions.transactionMonthlyAmount': 'Estimated Monthly Transaction Amount',
    'custodyKyc.company.descriptions.EstimatedAnnualIncome': 'Estimated Annual Business Revenue',
    'custodyKyc.company.descriptions.CRPInformation': 'Corporate Related Person Information',
    'custodyKyc.crp.descriptions.Individuals': 'Individual',
    'custodyKyc.crp.descriptions.Institution': 'Institution',
    'custodyKyc.crp.descriptions.EnterpriseAnnexInformation': 'Enterprise Annex Information',
    'custodyKyc.crp.descriptions.FirstAdministratorsInformation': 'Corporate Administrators Information',
    'custodyKyc.crp.descriptions.PhoneNumber': 'Mobile Number',
    'custodyKyc.crp.descriptions.E-mail': 'E-mail',
    'custodyKyc.crp.descriptions.Title': 'Title',
    'custodyKyc.crp.descriptions.SocialContact': 'Social Network Contact',
    'custodyKyc.crp.descriptions.IdentityDocument': 'Identity Document',
    'custodyKyc.crp.descriptions.CRPInformation': 'Corporate Responsible Person Information',

    'custodyKyc.log.Title': 'Log',
    'custodyKyc.UserInfo.title': 'Individual Responsible Person Information',
    'custodyKyc.UserInfo.descriptions.Back': 'Back',
    'custodyKyc.UserInfo.descriptions.AdditionalInformation': 'Additional Information',
    'custodyKyc.UserInfo.descriptions.CDDInformation': 'CDD Information',
    'custodyKyc.UserInfo.descriptions.UserInformation': 'User‘s Basic Information',
    'custodyKyc.UserInfo.descriptions.licenseNumber': 'license number',
    'custodyKyc.UserInfo.descriptions.verifiedId': 'is the id verified',
    'custodyKyc.UserInfo.descriptions.certificateType': 'type of certificate',
    'custodyKyc.UserInfo.descriptions.passport': 'Passport',
    'custodyKyc.UserInfo.descriptions.idCard': 'National Identify Card',
    'custodyKyc.UserInfo.descriptions.drivingLicense': 'Driving License',
    'custodyKyc.UserInfo.descriptions.residencePermit': 'Residence Permit',
    // 'custodyKyc.UserInfo.descriptions.frontAndBack': ' front and back',
    'custodyKyc.UserInfo.descriptions.front': 'front',
    'custodyKyc.UserInfo.descriptions.back': 'back',
    'custodyKyc.UserInfo.descriptions.upload': 'upload ',
    'custodyKyc.UserInfo.button.AddIndividualCRP': 'Add Individual CRP',
    'custodyKyc.UserInfo.button.AddInstitutionCRP': 'Add Institution CRP',
    'custodyKyc.UserInfo.descriptions.files': 'files ',

    'custodyKyc.UserInfo.descriptions.ActualShareholdingPercentage': 'Actual Shareholding Percentage',
    'custodyKyc.UserInfo.descriptions.UploadID': 'Upload ID',
    'custodyKyc.UserInfo.label.Switch': 'Switch',

    'custodyKyc.UserInfo.descriptions.BirthDate': 'Date of Birth',
    'custodyKyc.UserInfo.descriptions.residence': 'Place of Residence',
    'custodyKyc.UserInfo.descriptions.Gender': 'Gender',
    'custodyKyc.UserInfo.descriptions.AddPRInformation': 'Add Company Related Person(s) Information',
    'custodyKyc.UserInfo.descriptions.businessCountry': 'Country of Business',
    'custodyKyc.UserInfo.descriptions.registeredCountry': 'Country of register',
    'custodyKyc.UserInfo.descriptions.CompanyName': 'Company Name',
    'custodyKyc.UserInfo.descriptions.AddCompanyCRP': 'Add the Information of Company Related Person',
    'custodyKyc.UserInfo.descriptions.CountryIssue': 'Country of Issue',
    'custodyKyc.UserInfo.descriptions.UpdateTime': 'Update Time',
    'custodyKyc.UserInfo.descriptions.DateCreation': 'Date of Creation',
    'custodyKyc.compliance.title': 'Regulatory Information',
    'custodyKyc.UserInfo.descriptions.AuthorisedSignatory': 'Authorisation Letter for Authorised Signatory',
    'custodyKyc.UserInfo.descriptions.IdentityDocument': 'Identity Document',
    'custodyKyc.UserInfo.descriptions.AuthorisedSignatoryInfo': 'Authorized Signatory Information',
    'custodyKyc.UserInfo.descriptions.AdministratorIDDocument': 'Administrator ID document ',

    // 规管信息
    'custodyKyc.compliance.promiseLegal':
        " I/My company promise that the purpose of using Cobo's services is legal, and the relevant funds processed are not the proceeds of any crime." +
        ' I/ My company confirm and promise that all the above information and materials are true, legal and valid, and understand that Cobo will rely on and use the accuracy and completeness of the statements made and the information provided. I/ My company agree that during the service period, Cobo may request information updates from time to time, and is willing to cooperate in providing relevant information.',
    'custodyKyc.compliance.hasAdoptedPolicies':
        'Whether your company has adopted any policies or measures for anti-money laundering',
    'custodyKyc.compliance.hasArbitrationOrPenalties':
        'As of the date of this form, is there any existing, ongoing, potential, or foreseeable litigation, arbitration or penalties imposed on your company?',
    'custodyKyc.compliance.threeYearsHasArbitrationOrPenalties':
        'As of the date of this form,is there any existing, ongoing, potential, or foreseeable litigation, arbitration or penalties imposed on your company’s directors, authorized signatories, shareholders, ultimate controllers in the past three years?',
    'custodyKyc.compliance.hasRelevantApplicableLaws':
        'Has your company been warned, punished, penalized or enforced by any supervisory authority or under any relevant applicable laws or regulations?',
    'custodyKyc.compliance.hasDepositsFraudOrICO':
        'Whether your company, shareholders, directors, authorized signatories, ultimate controllers have been investigated by any regulatory agencies in any countries or regions or involved in any arbitration, litigation or other judicial procedures for money laundering, illegal use of public deposits, fraud, or ICO, etc?',
    'custodyKyc.compliance.materialsIsValid':
        'Do you/Does your company hereby confirm and commit that ALL above mentioned information and materials are true, legal, and valid?',
    'custodyKyc.compliance.agreeingBelow':
        'By agreeing below, the client confirms that the purpose for using the Cobo’s service is legitimate, the funds concerned, handled or dealt with, are not proceeds from any crime.The customer understands that Cobo will be relying on the accuracy and completeness of the statements made and information provided, and the customer hereby certifies that the information and data given are true, complete and correct, and will promptly inform Cobo of any changes.',
};
export default custodyKyc;
