import {
    BlockOutlined,
    SwapOutlined,
    WalletOutlined,
    DollarOutlined,
    ControlOutlined,
    AreaChartOutlined,
    FileSyncOutlined,
    AuditOutlined,
    AccountBookOutlined,
    TeamOutlined,
    ToolOutlined,
    PartitionOutlined,
    SolutionOutlined,
    ScheduleOutlined,
} from '@ant-design/icons';
/**
 * 路由结构。
 *
 * @typedef {Object} Route
 * @property {string} path - 路由的URL路径。注：1. 不要以'/'结尾。2.route参数可以用正则表达式匹配。
 * @property {string} key - 路由的唯一键。注：key为多语言key
 * @property {ReactNode} icon - 路由的图标组件。
 * @property {Array<Route>} children - 路由的子路由。
 * @property {Array<string>} auth - 访问路由所需的授权权限列表，不设置时表示有权限。
 * @property {boolean} hidden - 指示路由是否应该在导航菜单中隐藏，默认false。 注：在导航菜单中隐藏的路由不会在左侧菜单中显示。一般用于详情页
 *
 * @type {Array<Route>}
 */
const routes = [
    // custody
    {
        path: '/custody',
        key: 'aladdin.custody',
        icon: <WalletOutlined />,
        children: [
            {
                path: '/custody/custodyOrganization',
                auth: ['custody_org_edit', 'custody_org_view'],
                key: 'custody.custodyOrganization.custodyUserProduct',
            },
            {
                path: '/custody/custodyOrganization/detail',
                auth: ['custody_org_edit', 'custody_org_view'],
                key: 'custody.custodyOrganization.custodyUserProduct.detail',
                hidden: true,
            },
            {
                path: '/custody/localDangerMsanagement',
                auth: ['custody_danger_coin_view', 'custody_danger_coin_edit'],
                key: 'transaction.local-danger-management',
            },
            {
                path: '/custody/custodyWithdrawExploer',
                auth: ['custody_transaction_view', 'custody_transaction_edit'],
                key: 'transaction.custody-withdraw-explorer.old',
            },
            {
                path: '/custody/withdrawExploer',
                auth: ['custody_transaction_view', 'custody_transaction_edit'],
                key: 'transaction.custody-withdraw-explorer',
            },
            {
                path: '/custody/withdrawExploer/detail/\\d+',
                hidden: true,
                auth: ['custody_transaction_view', 'custody_transaction_edit'],
                key: 'transaction.custody-withdraw-explorer-detail',
            },
            {
                path: '/custody/mpcWithdrawExploer',
                auth: ['custody_mpc_withdraw_view', 'custody_mpc_withdraw_edit'],
                key: 'transaction.custody-mpc-withdraw-explorer',
            },
            {
                path: '/custody/mpcWithdrawExploer/detail/\\d+',
                hidden: true,
                auth: ['custody_transaction_view', 'custody_transaction_edit'],
                key: 'transaction.custody-mpc-withdraw-explorer-detail',
            },
            {
                path: '/custody/portalWithdrawExploer',
                auth: ['custody_transaction_view', 'custody_transaction_edit'],
                key: 'transaction.portal-withdraw-explorer',
            },
            {
                path: '/custody/portalWithdrawExploer/detail/\\d+',
                hidden: true,
                auth: ['custody_transaction_view', 'custody_transaction_edit'],
                key: 'transaction.portal-withdraw-explorer-detail',
            },
            {
                path: '/custody/withdrawalApprove',
                auth: ['approve_template_view', 'approve_template_edit'],
                key: 'transaction.custody-large-amount-withdraw-approve-config',
            },
            {
                path: '/custody/inboundTx',
                auth: ['user_asset_inboundtx_view', 'user_asset_inboundtx_edit'],
                key: 'transaction.inbound-transaction-management',
            },
            {
                path: '/custody/mpcInboundTx',
                auth: ['custody_mpc_withdraw_view', 'custody_mpc_withdraw_edit'],
                key: 'transaction.mpc-inbound-transaction-management',
            },
            {
                path: '/custody/suspendedManagement',
                auth: ['custody_coin_suspended'],
                key: 'transaction.custody-suspended-management',
            },
            {
                path: '/custody/coinManage',
                auth: ['custody_coin_view', 'custody_coin_edit'],
                key: 'custody.coinManage',
            },
            {
                path: '/custody/financeConfig',
                //   exact: true,
                auth: ['custody_financial_product_edit', 'custody_financial_product_view'],
                key: 'custody.financeConfig',
            },
            {
                path: '/custody/orgkeyAuthAdvance',
                auth: ['apikey_event_list_view', 'apikey_event_operation'],
                key: 'custody.custodyOrgkeyAuthAdvance',
            },
            {
                path: '/custody/pushMessage',
                auth: ['custody_transaction_view', 'custody_transaction_edit'],
                key: 'transaction.custodyPushMessageManagement',
            },
            {
                path: '/custody/threePartyHostingApplication',
                auth: ['custody_tss_node_apply_view', 'custody_tss_node_apply_audit'],
                key: 'transaction.threePartyHostingApplication',
            },
            {
                path: '/custody/threePartyHostingList',
                auth: ['custody_tss_node_provider_edit', 'custody_tss_node_provider_view'],
                key: 'transaction.threePartyHostingList',
            },
            {
                path: '/custody/superloop',
                auth: ['superloop_credit_view'],
                key: 'custody.superloop.siderBar.title',
            },
            {
                path: '/custody/superloop/detail/\\d+',
                auth: ['superloop_credit_view'],
                key: 'custody.superloop.siderBar.detail.title',
                hidden: true,
            },
            {
                path: '/custody/superloopExchange',
                auth: ['superloop_exchange_view', 'superloop_exchange_export'],
                key: 'custody.superloopExchange.siderBar.title',
            },
            {
                path: '/custody/superloopExchange/detail/\\d+',
                auth: ['superloop_exchange_view', 'superloop_exchange_export'],
                key: 'custody.superloopExchange.siderBar.detail.title',
                hidden: true,
            },
            {
                path: '/custody/tightenRiskControl',
                auth: ['one_click_tightening_view', 'one_click_tightening_create'],
                key: 'custody.tightenRiskControl.siderBar.title',
            },
            {
                path: '/custody/mpcKyt',
                auth: ['mpc_tx_kyt_view'],
                key: 'custody.mpckyt.siderBar.title',
            },
            {
                path: '/custody/mpcKyt/detail/\\d+',
                auth: ['mpc_tx_kyt_view'],
                key: 'custody.mpckytDetail.siderBar.title',
                hidden: true,
            },
        ],
    },
    {
        path: '/tss',
        key: 'aladdin.tss',
        icon: <PartitionOutlined />,
        children: [
            {
                path: '/tss/tssRelay',
                auth: ['tss_relay_request_view', 'tss_relay_request_edit', 'tss_relay_request_force_reset'],
                key: 'tss.tssRelay',
            },
            {
                path: '/tss/appManage',
                auth: ['tss_relay_app_view', 'tss_relay_app_edit'],
                key: 'tss.appManage',
            },
            {
                path: '/tss/nodeRegister',
                auth: ['tss_relay_bind_view', 'tss_relay_bind_edit'],
                key: 'tss.nodeRegister',
            },
            {
                path: '/tss/nodesManage',
                auth: ['tss_relay_node_view', 'tss_relay_node_edit'],
                key: 'tss.nodeManage',
            },
            {
                path: '/tss/asynqmo',
                auth: ['tss_relay_queue_edit', 'tss_relay_queue_view'],
                key: 'tss.asynqmon',
                children: [
                    {
                        path: '/tss/asynqmon',
                        auth: ['tss_relay_queue_view', 'tss_relay_queue_edit'],
                        key: 'tss.queues',
                    },
                    {
                        path: '/tss/asynqmon/servers',
                        auth: ['tss_relay_queue_view', 'tss_relay_queue_edit'],
                        key: 'tss.servers',
                    },
                    {
                        path: '/tss/asynqmon/redis',
                        auth: ['tss_relay_queue_view', 'tss_relay_queue_edit'],
                        key: 'tss.redis',
                    },

                    {
                        path: '/tss/asynqmon/queues/\\w+',
                        auth: ['tss_relay_queue_view', 'tss_relay_queue_edit'],
                        key: 'tss.queuesDetail',
                        hidden: true,
                    },
                    {
                        path: '/tss/asynqmon/queues/\\w+/tasks/\\S+',
                        auth: ['tss_relay_queue_view', 'tss_relay_queue_edit'],
                        key: 'tss.tasksDetail',
                        hidden: true,
                    },
                ],
            },
        ],
    },
    // wallet
    {
        path: '/wallet',
        key: 'aladdin.wallet',
        icon: <SwapOutlined />,
        children: [
            {
                path: '/wallet/transactionExplorer',
                auth: ['transaction_outbound_view', 'transaction_outbound_retry', 'transaction_outbound_fail'],
                key: 'transaction.wallet-transaction-explorer',
            },
            {
                path: '/wallet/pendingApproval',
                auth: ['cloud_transaction_view', 'cloud_transaction_edit'],
                key: 'transaction.pending-approval',
            },
            {
                path: '/wallet/verifyTxApproval',
                auth: ['cloud_verify_transaction_view', 'cloud_verify_transaction_edit'],
                key: 'transaction.wallet-verify-transaction-explorer',
            },
            {
                path: '/wallet/internalTxPendingApproval',
                auth: ['cloud_transaction_view', 'cloud_transaction_edit'],
                key: 'transaction.internal-pending-approval',
            },
            {
                path: '/wallet/suspendedManagement',
                auth: ['wallet_coin_suspended'],
                key: 'transaction.wallet-suspended-management',
            },
            {
                path: '/wallet/newInternalTxFailApproval',
                auth: ['cloud_transaction_view', 'cloud_transaction_edit'],
                key: 'transaction.new-internal-pending-approval',
            },
            {
                path: '/wallet/newVerifyFailTxApproval',
                auth: ['cloud_verify_transaction_view', 'cloud_verify_transaction_edit'],
                key: 'transaction.new-wallet-verify-transaction-explorer',
            },
            {
                path: '/wallet/walletUserManager',
                auth: ['wallet_user_manager_management'],
                key: 'crm.walletUserMangerManagement',
            },
            {
                path: '/wallet/cloudWallet',
                auth: ['wallet_view', 'wallet_edit'],
                key: 'crm.cobo-wallet-user',
            },
        ],
    },
    // blockchain
    {
        path: '/blockchain',
        key: 'aladdin.blockchain',
        icon: <BlockOutlined />,
        children: [
            {
                path: '/blockchain/deposit',
                auth: ['blockchain_deposit_view', 'blockchain_deposit_edit'],
                key: 'blockchain.deposit',
            },
            {
                path: '/blockchain/tokenManagement',
                auth: ['blockchain_token_update'],
                key: 'blockchain.tokenManagement',
            },
            {
                path: '/blockchain/manualCollectFunds',
                auth: ['blockchain_manual_collect_funds'],
                key: 'blockchain.manualCollectFunds',
            },
            // {
            //     path: '/blockchain/manualCollectFundsOld',
            //     auth: ['blockchain_manual_collect_funds'],
            //     key: '手动资金归集(旧)',
            // },
            {
                path: '/blockchain/manualTransaction',
                auth: ['blockchain_manual_advance_transaction'],
                key: 'blockchain.manualAdvanceTransaction',
            },
            {
                path: '/blockchain/transactionRetryResend',
                auth: ['blockchain_transaction_view'],
                key: 'blockchain.transactionRetryResend',
            },
            {
                path: '/blockchain/manualInbound',
                auth: ['manual_inbound_edit'],
                key: 'blockchain.manualInbound',
            },
            {
                path: '/blockchain/manualInbound/history',
                auth: ['manual_inbound_edit'],
                key: 'blockchain.manualInbound',
                hidden: true,
            },
        ],
    },
    // 风控
    {
        path: '/riskControl',
        //   exact: true,
        key: 'aladdin.risk_control',
        icon: <ControlOutlined />,
        children: [
            //            {
            //                path: '/riskControl/custodyUserAuthAdvance',
            //                auth: ['risk_event_list_view', 'risk_event_operation'],
            //                key: 'risk_control.custodyUserAuthAdvance',
            //            },
            {
                path: '/riskControl/custodyUserAuthAdvanceNew',
                auth: ['risk_event_list_view', 'risk_event_operation'],
                key: 'risk_control.custodyUserAuthAdvanceNew',
            },
            {
                path: '/riskControl/userManager',
                auth: ['risk_user_list_view'],
                key: 'risk_control.custodyUserManager',
            },
            // {
            //     path: '/riskControl/userManagerOld',
            //     auth: ['risk_user_list_view'],
            //     key: '用户信息（旧）',
            // },
            {
                path: '/riskControl/pubkeyManager',
                auth: ['risk_pubkey_list_view'],
                key: 'risk_control.custodyPubkeyManager',
            },
            // {
            //     path: '/riskControl/pubkeyManagerOld',
            //     auth: ['risk_pubkey_list_view'],
            //     key: '公钥信息（旧）',
            // },
            {
                path: '/riskControl/statementManager',
                auth: ['risk_statement_list_view'],
                key: 'risk_control.custodyStatementManager',
            },
            // {
            //     path: '/riskControl/statementManagerOld',
            //     auth: ['risk_statement_list_view'],
            //     key: '审核信息（旧）',
            // },
            {
                path: '/riskControl/withdrawalOrgReviewManager',
                auth: ['risk_withdrawal_org_review_list_view'],
                key: 'risk_control.custodyWithdrawalOrgReviewManager',
            },
            // {
            //     path: '/riskControl/withdrawalOrgReviewManagerOld',
            //     auth: ['risk_withdrawal_org_review_list_view'],
            //     key: '提币Org审核记录(旧)',
            // },
            {
                path: '/riskControl/customRuleManager',
                auth: ['risk_custom_rule_list_view'],
                key: 'risk_control.custodyCustomRuleManager',
            },
            // {
            //     path: '/riskControl/customRuleManagerOld',
            //     auth: ['risk_custom_rule_list_view'],
            //     key: '客户自定义规则(旧)',
            // },
            // Authchain Info
            {
                path: '/riskControl/authchainManager',
                auth: ['risk_auth_chain_query'],
                key: 'risk_control.authchainManager',
            },
            // 风控规则
            {
                path: '/riskControl/bpmnRulesManager',
                auth: ['risk_auth_bpmn_rules_query'],
                key: 'riskControl.ruleManagement',
            },
            {
                path: '/riskControl/bpmnDetail',
                auth: ['risk_auth_bpmn_rules_query'],
                key: 'riskControl.rulesDetail',
                hidden: true,
            },
            {
                path: '/riskControl/bpmnUpload',
                auth: ['risk_auth_bpmn_rules_edit'],
                key: 'riskControl.rulesUpload',
                hidden: true,
            },
            {
                path: '/riskControl/bpmnVersions',
                auth: ['risk_auth_bpmn_rules_query'],
                key: 'riskControl.rulesVersions',
                hidden: true,
            },
        ],
    },
    // 特别添加： 风控名单管理
    {
        path: '/riskControlMenu',
        key: 'aladdin.risk_menu',
        icon: <SolutionOutlined />,
        children: [
            // 名单项目管理
            {
                path: '/riskMenu/menuEvent',
                auth: ['risk_list_view', 'risk_list_edit'],
                key: 'riskMenu.menuEvent',
            },
            // 用户Id管理
            {
                path: '/riskMenu/userId',
                auth: ['risk_list_view', 'risk_list_edit'],
                key: 'riskMenu.menuEvent.userId',
            },
            // Org Id管理
            {
                path: '/riskMenu/orgId',
                auth: ['risk_list_view', 'risk_list_edit'],
                key: 'riskMenu.menuEvent.orgId',
            },
            // IP 地址管理
            {
                path: '/riskMenu/ip',
                auth: ['risk_list_view', 'risk_list_edit'],
                key: 'riskMenu.menuEvent.ip',
            },
            // 移动设备识别码 IMEI 管理
            {
                path: '/riskMenu/imei',
                auth: ['risk_list_view', 'risk_list_edit'],
                key: 'riskMenu.menuEvent.imei',
            },
            // phone 手机号管理
            {
                path: '/riskMenu/phone',
                auth: ['risk_list_view', 'risk_list_edit'],
                key: 'riskMenu.menuEvent.phone',
            },
            // 支付账号管理
            {
                path: '/riskMenu/pay',
                auth: ['risk_list_view', 'risk_list_edit'],
                key: 'riskMenu.menuEvent.pay',
            },
            // 收款地址管理
            {
                path: '/riskMenu/toAddress',
                auth: ['risk_list_view', 'risk_list_edit'],
                key: 'riskMenu.menuEvent.toAddress',
            },
            // 用户-提币地址管理
            {
                path: '/riskMenu/individualToAddress',
                auth: ['risk_list_view', 'risk_list_edit'],
                key: 'riskMenu.menuEvent.individualToAddress',
            },
            // 用户-设备管理
            {
                path: '/riskMenu/userDevice',
                auth: ['risk_list_view', 'risk_list_edit'],
                key: 'riskMenu.menuEvent.userDevice',
            },
            // 用户-IP 管理
            {
                path: '/riskMenu/userIp',
                auth: ['risk_list_view', 'risk_list_edit'],
                key: 'riskMenu.menuEvent.userIp',
            },
            // Org-IP 管理
            {
                path: '/riskMenu/orgIp',
                auth: ['risk_list_view', 'risk_list_edit'],
                key: 'riskMenu.menuEvent.orgIp',
            },
            // Org-UA 管理
            {
                path: '/riskMenu/orgUA',
                auth: ['risk_list_view', 'risk_list_edit'],
                key: 'riskMenu.menuEvent.orgUA',
            },
            // 邮箱管理
            {
                path: '/riskMenu/email',
                auth: ['risk_list_view', 'risk_list_edit'],
                key: 'riskMenu.menuEvent.email',
            },
        ],
    },
    // 仓位管理
    {
        path: '/position',
        key: 'aladdin.position',
        icon: <AreaChartOutlined />,
        children: [
            {
                path: '/position/PositionManagement',
                auth: ['mortgage_view', 'mortgage_edit'],
                key: 'position.PositionManagement',
            },
            {
                path: '/position/mortgageOrderEdit',
                auth: ['mortgage_view', 'mortgage_edit'],
                key: 'configration.MortgageOrderManagement',
            },
        ],
    },
    // 运营配置管理
    {
        path: '/walletOperation',
        key: 'aladdin.configuration',
        icon: <FileSyncOutlined />,
        children: [
            {
                path: '/walletOperation/custodySettleRate',
                //   exact: true,
                auth: ['financial_product_custody_settle_rate_edit', 'financial_product_custody_settle_rate_view'],
                key: 'configration.custodySettleRate',
            },
            {
                path: '/walletOperation/financialScene',
                auth: ['financial_product_list_configration'],
                key: 'configration.walletFinancialListPageConfigration',
            },
            {
                path: '/walletOperation/financialProductV2',
                auth: ['financial_product_v2_view', 'financial_product_v2_edit'],
                key: 'configration.walletFinancialProductManagement',
            },
            {
                path: '/walletOperation/publicMessage',
                auth: ['public_message_view', 'public_message_edit'],
                key: 'configration.publicMessage',
            },
            {
                path: '/walletOperation/walletUpdateMessage',
                auth: ['update_message_view', 'update_message_edit'],
                key: 'configration.walletUpdateMessage',
            },
            {
                path: '/walletOperation/financialClassify',
                auth: ['financial_classify_config_view', 'financial_classify_config_edit'],
                key: 'configration.financialClassifyConfig',
            },
            //闪兑配置下线
            // {
            //     path: '/walletOperation/exchange',
            //     auth: ['financial_product_view', 'financial_product_edit'],
            //     key: 'configration.exchange',
            // },
            {
                path: '/walletOperation/banner',
                auth: ['banner_view', 'banner_edit'],
                key: 'configration.bannerConfig',
            },
            {
                path: '/walletOperation/reward',
                auth: ['activity_reward_view', 'activity_reward_edit', 'activity_reward_examine'],
                key: 'configration.rewardConfig',
            },
            // {
            //     path: '/walletOperation/rewardOld',
            //     auth: ['activity_reward_view', 'activity_reward_edit', 'activity_reward_examine'],
            //     key: '运营活动发奖(旧)',
            // },
            {
                path: '/walletOperation/mortgageCustomize',
                auth: ['mortgage_customize_view', 'mortgage_customize_edit'],
                key: 'configration.mortgageCustomizeConfig',
            },
            {
                path: '/walletOperation/mortgageLoanCoinInfo',
                auth: ['mortgage_view', 'mortgage_edit'],
                key: 'configration.mortgageLoanCoinInfoConfig',
            },
            {
                path: '/walletOperation/mortgageCoinPair',
                auth: ['mortgage_view', 'mortgage_view'],
                key: 'configration.mortgageCoinPairConfig',
            },
            {
                path: '/walletOperation/mortgageRepayment',
                auth: ['mortgage_view', 'mortgage_view'],
                key: 'configration.mortgageRepaymentConfig',
            },
            {
                path: '/walletOperation/mortgageDuration',
                auth: ['mortgage_view', 'mortgage_edit'],
                key: 'configration.mortgageDurationConfig',
            },
            {
                path: '/walletOperation/mortgageOrderEdit',
                auth: ['mortgage_view', 'mortgage_edit'],
                key: 'configration.mortgageOrderEdit',
            },
            {
                path: '/walletOperation/mortgageBanner',
                auth: ['mortgage_view', 'mortgage_edit'],
                key: 'configration.mortgageBannerConfig',
            },
            {
                path: '/walletOperation/hodlProduct',
                auth: ['triple_product_edit'],
                key: 'configration.hodlProductConfig',
            },
            {
                path: '/walletOperation/hodlSupportCoin',
                auth: ['triple_product_edit'],
                key: 'configration.hodlSupportCoinConfig',
            },
            {
                path: '/walletOperation/hodlCustomize',
                auth: ['triple_product_edit'],
                key: 'configration.hodlCustomizeConfig',
            },
            {
                path: '/walletOperation/circle',
                auth: ['circle_edit'],
                key: 'configration.circleConfig',
            },
            {
                path: '/walletOperation/namelist',
                auth: ['circle_edit'],
                key: 'configration.namelistConfig',
            },

            {
                path: '/walletOperation/mobileComp',
                auth: ['mobile_component_view', 'mobile_component_edit'],
                key: 'configration.mobileComponentConfig',
            },

            {
                path: '/walletOperation/financialProductForm',
                auth: ['financial_product_v2_view', 'financial_product_v2_edit'],
                // key: 'configration.mobileComponentConfig',
                hidden: true,
            },
            {
                path: '/walletOperation/financialProductForm/add',
                auth: ['financial_product_v2_view', 'financial_product_v2_edit'],
                // key: 'configration.mobileComponentConfig',
                hidden: true,
            },
            {
                path: '/walletOperation/bannerConf',
                auth: ['financial_product_list_configration'],
                hidden: true,
            },
        ],
    },
    // KYC管理
    {
        path: '/kyc',
        key: 'aladdin.kyc',
        icon: <AuditOutlined />,
        children: [
            {
                path: '/kyc/walletKYCAudit',
                auth: ['wallet_kyc_view', 'wallet_kyc_pre_audit', 'wallet_kyc_compliance_audit'],
                key: 'kyc.wallet.audit',
            },
            // {
            //     path: '/kyc/walletKYCAuditOld',
            //     auth: ['wallet_kyc_view', 'wallet_kyc_pre_audit', 'wallet_kyc_compliance_audit'],
            //     key: 'Wallet用户KYC（旧）',
            // },
            {
                path: '/kyc/custodyKYCAudit',
                auth: [
                    'custody_kyc_applicant',
                    'custody_kyc_compliance_audit',
                    'custody_t_kyc_pre_audit',
                    'custody_kyc_contract_upload',
                ],
                key: 'kyc.custody.audit',
            },
            {
                path: '/kyc/custodyKYCAuditNew',
                auth: [
                    'custody_kyc_applicant',
                    'custody_kyc_compliance_audit',
                    'custody_t_kyc_pre_audit',
                    'custody_kyc_contract_upload',
                ],
                key: 'kyc.custody.auditnew',
            },
            {
                path: '/kyc/custodyKYCAuditNew/detail',
                auth: [
                    'custody_kyc_applicant',
                    'custody_kyc_compliance_audit',
                    'custody_t_kyc_pre_audit',
                    'custody_kyc_contract_upload',
                ],
                hidden: true,
                key: 'kyc.custody.auditDetail',
            },
            {
                path: '/kyc/mpcKYCAudit',
                auth: [
                    'custody_kyc_applicant',
                    'custody_kyc_compliance_audit',
                    'custody_t_kyc_pre_audit',
                    'custody_kyc_contract_upload',
                ],
                key: 'kyc.custody.mpcAudit',
            },
            {
                path: '/kyc/mpcKYCAudit/detail',
                auth: [
                    'custody_kyc_applicant',
                    'custody_kyc_compliance_audit',
                    'custody_t_kyc_pre_audit',
                    'custody_kyc_contract_upload',
                ],
                hidden: true,
                key: 'kyc.custody.mpcAudit.detail',
            },
            {
                path: '/kyc/cardKycAudit',
                auth: [
                    'custody_cobo_card_kyc_view',
                    'custody_cobo_card_kyc_edit',
                    // 'custody_t_kyc_pre_audit',
                    // 'custody_kyc_contract_upload',
                ],
                key: 'kyc.custody.cardKycAudit',
            },
            {
                path: '/kyc/cardKycAudit/detail',
                auth: ['custody_cobo_card_kyc_view', 'custody_cobo_card_kyc_edit'],
                hidden: true,
                key: 'kyc.custody.cardKycAudit.detail',
            },
        ],
    },
    // 记账工具
    {
        path: '/bookkeeping',
        key: 'aladdin.manual_bookkeeping',
        icon: <AccountBookOutlined />,
        children: [
            {
                path: '/bookkeeping/transaction',
                auth: ['manual_bookkeeping_transaction_mark', 'manual_bookkeeping_transaction_view'],
                key: 'manual_bookkeeping.transaction',
            },
            {
                path: '/bookkeeping/address',
                auth: ['manual_bookkeeping_address_view', 'manual_bookkeeping_address_mark'],
                key: 'manual_bookkeeping.address',
            },
            {
                path: '/bookkeeping/transactionLink',
                auth: ['manual_bookkeeping_address_view', 'manual_bookkeeping_address_mark'],
                hidden: true,
            },
        ],
    },
    // 薪酬系统
    {
        path: '/coboBonus',
        //   exact: true,
        key: 'aladdin.cobo_bonus',
        icon: <DollarOutlined />,
        children: [
            {
                path: '/coboBonus/coboEmployee',
                auth: ['cobo_employee_view', 'cobo_employee_edit'],
                key: 'cobo_bonus.employee',
            },
            {
                path: '/coboBonus/coboBonus',
                auth: ['cobo_bonus_view', 'cobo_bonus_apply', 'cobo_bonus_audit', 'cobo_bonus_send_confirm'],
                key: 'cobo_bonus.bonus',
            },
        ],
    },
    // 管理员
    {
        path: '/admin',
        key: 'aladdin.admin',
        icon: <TeamOutlined />,
        children: [
            {
                path: '/admin/user',
                auth: ['user_view', 'user_edit'],
                key: 'admin.team',
            },
        ],
    },
    //审批系统
    {
        path: '/approvalSystem',
        key: 'aladdin.approvalSystem',
        // auth: ['workflow_view', 'workflow_edit'],
        icon: <ScheduleOutlined />,
        children: [
            {
                path: '/approvalSystem/approvalManage',
                auth: ['workflow_edit'],
                key: 'approvalSystem.approvalManage',
            },
            {
                path: '/approvalSystem/approvalManage/createApprovalProcess',
                auth: ['workflow_edit'],
                key: 'approvalSystem.createApprovalProcess',
                hidden: true,
            },
            {
                path: '/approvalSystem/approvalManage/approvalProcessDetail/edit',
                key: 'approvalSystem.approvalProcessDetail',
                auth: ['workflow_edit'],
                hidden: true,
            },
            {
                path: '/approvalSystem/approvalManage/approvalProcessDetail/view',
                key: 'approvalSystem.approvalProcessDetail',
                auth: ['workflow_edit'],
                hidden: true,
            },
            {
                path: '/approvalSystem/initApproval',
                // auth: ['workflow_view', 'workflow_edit'],
                key: 'approvalSystem.initApproval',
            },
            {
                path: '/approvalSystem/initApproval/createApproval',
                // auth: ['workflow_view', 'workflow_edit'],
                key: 'approvalSystem.createApproval',
                hidden: true,
            },
            {
                path: '/approvalSystem/approvalCenter',
                // auth: ['workflow_view', 'workflow_edit'],
                key: 'approvalSystem.approvalCenter',
            },
        ],
    },
    // argus
    {
        path: '/argus',
        key: 'aladdin.argus',
        icon: <WalletOutlined />,
        children: [
            {
                path: '/argus/bot_instances',
                auth: ['argus_bots_view'],
                key: 'argus.bot_instances',
            },
        ],
    },
    // 工具箱
    {
        path: '/tools',
        key: 'aladdin.tools',
        icon: <ToolOutlined />,
        children: [
            {
                path: '/tools/scriptTools',
                auth: ['script_view', 'script_execute', 'script_allot'],
                key: 'tools.script',
            },
            {
                path: '/tools/fileUpload',
                auth: ['file_upload'],
                key: 'tools.file_upload',
            },
            {
                path: '/tools/telegram',
                auth: ['tg_group_send'],
                key: 'tools.telegram',
            },
        ],
    },
    {
        path: '/',
        key: 'aladdin.home',
        hidden: true,
    },
    {
        path: '/aladdin',
        hidden: true,
        children: [
            {
                path: '/aladdin/404',
                hidden: true,
                key: 'aladdin.404',
            },
            {
                path: '/aladdin/self',
                hidden: true,
                key: 'aladdin.user',
            },
        ],
    },
];

export default routes;
